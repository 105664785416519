import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	Dimensions,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { api_qrscanned } from "../Api";
import axios from "axios";
import { Fade } from "react-reveal";

import "./ripples.css";
import { APP_COLOURS } from "./ChallengeApp/APP_VARIABLES";

const TrackVisitorAndIP = async () => {
	try {
		const {
			data: { ip },
		} = await axios.get("https://api.ipify.org/?format=json"); // get the user's IP address

		const { data } = await axios.get(
			`https://ipinfo.io/${ip}/json?token=YOUR_TOKEN_HERE`,
		); // get the user's geolocation data

		const analyticsData = {
			ipAddress: data.ip,
			city: data.city,
			region: data.region,
			country: data.country,
			lat: data.loc.split(",")[0],
			lon: data.loc.split(",")[1],
			userAgent: window.navigator.userAgent,
			screenWidth: window.screen.width,
			screenHeight: window.screen.height,
		};

		console.log("TrackVisitorAndIP analyticsData", analyticsData);

		// axios.post("https://example.com/analytics", analyticsData); // send analytics data to server

		return analyticsData;
	} catch (error) {
		console.error(error);
	}
};

function generateFormId() {
	let formId = "";
	const characters = "abcdef0123456789";
	for (let i = 0; i < 16; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		const randomCharacter = characters[randomIndex];
		if (i === 4 || i === 8 || i === 12) {
			formId += "-";
		}
		formId += randomCharacter;
	}
	return formId;
}

const geoAppify1 = async () => {
	try {
		const { data } = await axios.get(`https://ipapi.co/json/`);
		let uuid = generateFormId();
		const analyticsData = {
			...data,
			userAgent: window.navigator.userAgent,
			screenWidth: window.screen.width,
			screenHeight: window.screen.height,
			uuid: uuid,
		};

		const set_ = await localStorage.setItem(
			"analyticsData",
			JSON.stringify(analyticsData),
		);
		const set_uuid = await localStorage.setItem("uuid", uuid);

		return analyticsData;
	} catch (error) {
		console.error(error);
		return null;
	}
};

const LandingPage = ({ navigation, retailerUrl }) => {
	const [loading, setLoading] = useState(true);
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(),
	);

	document.title = `ADVANCED LIGHT Ceramide Capsules Strengthening & Refining Serum`;

	const [signupData, setsignupData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		cell: "",
		preferredMethod: "",
	});

	function runRedirectTimer(url) {
		const timer = setTimeout(() => {
			window.location = url;
		}, 1500);
		return () => clearTimeout(timer);
	}

	const pageLanded = async () => {
		const analyticsData = await JSON.parse(
			localStorage.getItem("analyticsData"),
		);

		const retailer = await localStorage.getItem("retailer");

		let ip = "";
		if (
			typeof analyticsData !== "undefined" &&
			analyticsData !== null &&
			analyticsData !== ""
		) {
			ip = await analyticsData;

			const gotoapp = await localStorage.getItem("gotoapp");

			// if (
			// 	typeof gotoapp !== "undefined" &&
			// 	typeof analyticsData.type !== "undefined"
			// ) {
			// 	runRedirectTimer("/app");
			// } else {
			// if (typeof analyticsData.type !== "undefined") {
			// 	runRedirectTimer("/challenge");
			// } else {
			runRedirectTimer("/whatsmyceramide");
			// }
			// }

			return;
		} else {
			ip = await geoAppify1();
			console.log("ip", ip);
		}

		console.log("analyticsData", analyticsData);

		let raw = {
			allocation: "EA - ACL QR CODE",
			...ip,
			retailer: retailer,
		};

		// console.log("raw", raw);
		// return;

		fetch(api_qrscanned, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(raw),
		})
			.then((response) => response.json())
			.then((data) => {
				runRedirectTimer("/whatsmyceramide");
			})
			.catch((error) => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	};

	function getWindowDimensions() {
		const { width, height } = Dimensions.get("window");
		return {
			width,
			height,
		};
	}

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		const retailer = localStorage.getItem("retailer");

		if (!retailer) {
			localStorage.setItem("retailer", retailerUrl);
		}

		pageLanded();
		// Subscribe to window resize events
		Dimensions.addEventListener("change", handleResize);

		// Unsubscribe from the event when the component is unmounted
		return () => {
			Dimensions.removeEventListener("change", handleResize);
		};
	}, []); // Empty array ensures that effect is only run on mount and unmount

	const originalWidth = 655;
	const originalHeight = 930;

	// Calculate the height based on the aspect ratio
	const height = (windowDimensions.width * originalHeight) / originalWidth;

	return (
		<View
			style={{
				// flex: 1,
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "#FFECBE",
				height: windowDimensions.height,
				width: windowDimensions.width,
				overflow: "hidden",
				// display: "none",
			}}>
			<ScrollView scrollEnabled={false}>
				<View
					style={{
						height: windowDimensions.height,
						width: windowDimensions.width,
					}}>
					<View
						style={{
							alignItems: "center",
							width: "100%",
							position: "absolute",
							top: 0,
						}}>
						<div class='fade-container'>
							<Image
								source={require("../assets/cera_bg.png")}
								onLoadEnd={() => setLoading(false)}
								style={{
									width: windowDimensions.width,
									height: windowDimensions.height,
									resizeMode: "cover",
								}}
							/>
						</div>
					</View>
					<Fade>
						<View
							style={{
								alignItems: "center",
								width: "100%",
								paddingTop: 90,
								height: windowDimensions.height,
								// flex: 1,
							}}>
							{/* <View
								style={{
									width: "100%",
									// justifyContent: "center",
									alignItems: "center",
									padding: 0,
									margin: 0,
								}}>
								<h1
									style={{
										fontSize: 44,
										padding: 0,
										margin: 0,
									}}>
									A New
								</h1>
								<View
									style={{
										top: -20,
									}}>
									<h1
										style={{
											fontSize: 44,
											padding: 0,
											margin: 0,
										}}>
										Gold Standard
									</h1>
								</View>
							</View> */}
							{/* <Image
						source={require("../assets/anew.png")}
						style={{
							width: windowDimensions.width * 0.8,
							height: 35,
							resizeMode: "contain",
						}}
					/>
					<Image
						source={require("../assets/golds.png")}
						style={{
							width: windowDimensions.width * 0.8,
							height: 35,
							resizeMode: "contain",
							marginTop: 20,
						}}
					/> */}
							{/* <Image
								source={require("../assets/NEW Advanced Light C.png")}
								style={{
									width: windowDimensions.width * 0.8,
									height: 40,
									resizeMode: "contain",
									marginTop: 15,
								}}
							/> */}
							{/* <View
								style={{
									top: -15,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 19,
									}}>
									<Text
										style={{
											fontFamily: "GothamBoldItalic",
											fontSize: 19,
										}}>
										NEW
									</Text>{" "}
									Advanced Light
								</Text>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 19,
									}}>
									Ceramide Capsule
								</Text>
							</View> */}
							<View
								style={{
									top: -15,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 25,
									}}>
									ADVANCED LIGHT
								</Text>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 19,
									}}>
									Ceramide Capsules
								</Text>
								<Text
									style={{
										fontFamily: "Gotham",
										fontSize: 19,
									}}>
									Strengthening & Refining Serum
								</Text>
							</View>
						</View>
					</Fade>

					<View
						style={{
							alignItems: "center",
							width: "100%",
							position: "absolute",
							bottom: 0,
						}}>
						<Image
							source={require("../assets/el_logo.png")}
							style={{
								width: windowDimensions.width * 0.8,
								height: 90,
								resizeMode: "contain",
								marginBottom: 40,
							}}
						/>
					</View>

					{/* {loading ? (
						<View
							style={{
								width: windowDimensions.width,
								height: windowDimensions.height,
								backgroundColor: "#FFECBE",
								justifyContent: "center",
								alignItems: "center",
								position: "absolute",
								top: 0,
							}}>
							<Image
								source={require("../assets/el_logo.png")}
								style={{
									width: windowDimensions.width * 0.8,
									height: 90,
									resizeMode: "contain",
									marginBottom: 40,
								}}
							/>
							<ActivityIndicator />
						</View>
					) : (
						<div class='container'>
							<div class='ripple'></div>
						</div>
					)} */}

					{/* <View
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<TouchableOpacity
					style={{
						width: "100%",
						height: 40,
						borderRadius: 12,
						backgroundColor: "#E3A081",
						alignItems: "center",
						justifyContent: "center",
						marginTop: 16,
						marginBottom: 80,
					}}
					onPress={() => (window.location.href = "/signupform")}>
					<Text
						style={{
							color: "#fff",
							fontSize: 16,
						}}>
						Start
					</Text>
				</TouchableOpacity>
			</View> */}
				</View>
			</ScrollView>
		</View>
	);
};

export default LandingPage;
