import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import moment from "moment";

import { APP_COLOURS } from "./APP_VARIABLES";

let product1 = {
	Dry: {
		text: "Advanced Creamide Light",
		img: require("./ea_acl.png"),
	},
	Oily: {
		text: "Advanced Creamide",
		img: require("./ea_ac.png"),
	},
	Combination: {
		text: "Advanced Creamide",
		img: require("./ea_acl.png"),
	},
};

let product2 = {
	Hydration: {
		text: "HYALURONIC ACID",
		img: require("./ea_ha.png"),
	},
	Texture: {
		text: "RETINOL",
		img: require("./ea_re.png"),
	},
	Brightness: {
		text: "VITAMIN C",
		img: require("./ea_vc.png"),
	},
};

export default class JournalCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			analyticsData: "",

			product1Img: "",
			product2Img: "",
		};
	}

	componentDidMount() {
		let analyticsData = JSON.parse(localStorage.getItem("analyticsData"));

		console.log(analyticsData.type, analyticsData.concern);

		this.setState({
			product1Img: product1[analyticsData.type].img,
			product2Img: product2[analyticsData.concern].img,
		});
	}

	render() {
		let screenWidth = window.innerWidth;
		let screenHeight = window.innerHeight;
		const w = screenWidth / 6;
		const h = (screenWidth / 6) * 1.1;

		console.log(this.props.data);
		return (
			<View style={{ width: "100%" }}>
				<View
					style={{
						borderRadius: 20,
						// padding: 10,
					}}>
					<Text
						style={{
							color: "#0C122A",
							fontWeight: "300",
							fontSize: 12,
						}}>
						{this.props.data.title}
					</Text>
					<View
						style={{
							marginVertical: 5,
							flexDirection: "row",
							justifyContent: "space-between",
						}}>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<Text
								style={{
									color: "#0C122A",
									fontWeight: "800",
									marginRight: 10,
									fontSize: 16,
									// opacity: 0.6,
								}}>
								{moment(this.props.data.date).format("DD MMM")}:{" "}
							</Text>
						</View>

						{/* <Text
							style={{
								color: "#0C122A",
								fontWeight: "300",
								fontSize: 16,
							}}>
							{this.props.data.title}
						</Text> */}
					</View>
					<View style={{ width: "100%" }}>
						<View
							style={{
								flexDirection: "row",
								padding: 2,
								marginVertical: 3,
								// paddingLeft: 5,
							}}>
							<Image
								style={{
									marginRight: 10,
									width: 17,
									height: 17,
									resizeMode: "contain",
								}}
								source={require("../assets/images/timeline_time.png")}
							/>
							<Text
								style={{
									color: "#0C122A",
									fontWeight: "500",
									marginRight: 10,
									fontSize: 16,
								}}>
								{moment(this.props.data.date).format("HH:mm")}
							</Text>
						</View>
						<View
							style={{
								position: "absolute",
								top: 0,
								right: 0,
								flexDirection: "row",
							}}>
							<View style={{ padding: 2 }}>
								<Image
									source={this.state.product1Img}
									style={{
										width: 70,
										height: 80,
										marginRight: 10,
										resizeMode: "contain",
									}}
									resizeMode='contain'
								/>
							</View>
							<View style={{ padding: 2 }}>
								<Image
									source={this.state.product2Img}
									style={{
										width: 70,
										height: 80,
										marginRight: 10,
										resizeMode: "contain",
									}}
									resizeMode='contain'
								/>
							</View>
						</View>
						<View style={{ flexDirection: "row" }}>
							<View style={{ padding: 4 }}>
								<View
									style={{
										height: 50,
										width: 1,
										marginLeft: 1,
										opacity: 0.9,
										borderStyle: "dashed",
										borderWidth: 1,
										borderColor: "#E8E8E8",
										margin: -2,
										marginTop: 0,
									}}
								/>
							</View>
							{/* <View>
								<View style={{ flex: 1, flexDirection: "row" }}>
									<View
										style={{
											width: w,
											height: h,
											borderRadius: 10,
											backgroundColor: "white",
											marginHorizontal: 10,
											overflow: "hidden",
										}}>
										<Image
											style={{
												width: w,
												height: h,
												resizeMode: "cover",
											}}
											source={{
												uri: this.props.data.uri,
											}}
										/>
									</View>
								</View>
							</View> */}

							{/* <View style={{ padding: 2 }}>
								<Image
									source={require("../assets/images/adv_moisture.png")}
									style={{
										width: 70,
										height: 70,
										marginRight: 10,
										resizeMode: "contain",
									}}
									resizeMode='contain'
								/>
							</View> */}
						</View>
					</View>
				</View>
			</View>
		);
	}
}
