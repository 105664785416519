import React, { Component } from "react";
import {
	Image,
	Text,
	View,
	FlatList,
	Dimensions,
	TouchableOpacity,
	Animated,
	LayoutAnimation,
	Platform,
	UIManager,
	ScrollView,
} from "react-native";

import "./index.css";
import { Fade } from "react-reveal";

export default class Challenge extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// flex: 1,
					// backgroundColor: "#FFF",
					overflow: "hidden",
					height: window.screen.height,
					width: window.screen.width,
				}}>
				<ScrollView scrollEnabled={false}>
					<View
						style={{
							// height: 100,
							width: "100%",
							backgroundColor: "#FFF",
						}}>
						<Image
							source={require("./challenge_bannerOnl.png")}
							style={{
								width: window.screen.width,
								height: window.screen.width * (374.4 / 772),
								resizeMode: "contain",
							}}
						/>
						<View
							style={{
								width: "100%",
								// justifyContent: "center",
								// alignItems: "center",
								flex: 1,
								padding: 50,
							}}>
							<h1>3 Day </h1>
							<h1>
								Skin <i>Transformation</i> challenge
							</h1>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							paddingTop: 100,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 25,
								fontWeight: "300",
								marginBottom: 10,
							}}>
							COMING SOON!
						</Text>
					</View>
					<View
						style={{
							width: "100%",
							// justifyContent: "center",
							// alignItems: "center",
							flex: 1,
							padding: 50,
							display: "none",
						}}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								fontWeight: "300",
								marginBottom: 10,
							}}>
							Start now, or download the app
						</Text>

						<TouchableOpacity
							onPress={() => {
								window.location = "./app";

								const set_ = localStorage.setItem(
									"gotoapp",
									true,
								);
							}}>
							<View
								style={{
									padding: 17,
									// borderWidth: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#FFF",
									borderRadius: 10,
									marginBottom: 10,
								}}>
								<Text
									style={{
										fontFamily: "Gotham2",
										fontSize: 17,
										fontWeight: "500",
									}}>
									Start now
								</Text>
							</View>
						</TouchableOpacity>

						<Text
							style={{
								fontFamily: "Gotham",
								fontSize: 15,
								fontWeight: "300",
								marginBottom: 10,
								marginTop: 15,
							}}>
							Download the App
						</Text>
						<View
							style={{
								flexDirection: "row",
							}}>
							<TouchableOpacity
								style={{
									flex: 1,
									marginRight: 5,
								}}
								onPress={() => {
									this.setState({});
								}}>
								<Image
									source={require("./app_store.png")}
									style={{
										width: window.screen.width * 0.35,
										height: 50,
										resizeMode: "contain",
									}}
								/>
								{/* <View
									style={{
										flex: 1,
										padding: 17,
										// borderWidth: 1,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "#FFF",
										borderRadius: 2,
										marginBottom: 5,
									}}>
									<Text
										style={{
											fontFamily: "Gotham2",
											fontSize: 17,
											fontWeight: "500",
										}}>
										Apple iOS
									</Text>
								</View> */}
							</TouchableOpacity>
							<TouchableOpacity
								style={{
									flex: 1,
								}}
								onPress={() => this.setState({})}>
								<Image
									source={require("./google_play.png")}
									style={{
										width: window.screen.width * 0.35,
										height: 50,
										resizeMode: "contain",
									}}
								/>
								{/* <View
									style={{
										padding: 17,
										// borderWidth: 1,
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: "#FFF",
										borderRadius: 2,
										marginBottom: 5,
									}}>
									<Text
										style={{
											fontFamily: "Gotham2",
											fontSize: 17,
											fontWeight: "500",
										}}>
										Android
									</Text>
								</View> */}
							</TouchableOpacity>
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}
}
