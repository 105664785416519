import {Dimensions, LayoutAnimation, UIManager, Platform} from 'react-native';

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const APP_COLOURS = {
  PRIMARY: '#0068FF',
  PRIMARY2: '#FF5F5F',
  PRIMARY3: '#FFD76E',
  SECONDARY: '#C2EBD5',
  YELLOW: '#FFD04C',
  // BACKGROUND: '#1B1E22',
  BGMAIN: '#21282D',
  BACKGROUND: '#F0F3F6',
  BACKGROUND2: '#13171E',
  BACKGROUND_FADE: '#F0F3F6',
  BG3: '#2C343A',
  // BACKGROUND_FADE: '#191D24',
  // TEXTCOLOR: '#CCCCCC',
  BORDER: '#CCCCCC',
  TEXTCOLOR: '#191D24',
  TEXTCOLOR2: '#FFFFFF',
  TEXTCOLOR3: '#FC5C84',
  TEXTCOLOR4: '#878787',
  TEXTWHITE: '#E8E8E8',
  BLUE: '#518AFF',
  BLUE2: '#12326B',
  GREEN: '#59D654',
  PURPLE: '#996AFF',
  MINT: '#237B67',
};

export var CustomLayoutSpring = {
  duration: 400,
  create: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.9,
  },
  update: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.9,
  },
  // delete: {
  //   type: LayoutAnimation.Types.spring,
  //   property: LayoutAnimation.Properties.opacity,
  //   springDamping: 0.9,
  // },
};

export var CustomLayoutSpring2 = {
  duration: 400,
  create: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.9,
  },
  update: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.9,
  },
  delete: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.9,
  },
};

export const screenWidth = Math.round(Dimensions.get('window').width);
export const screenHeight = Math.round(Dimensions.get('window').height);

export const Avatars = [
  {
    // pp: require('./assets/images/profile.png'),
    avatar: 0,
  },
];

export const ethnicities = [
  'African',
  'Asian',
  'Caucasian',
  'Hispanic',
  'Native American',
  'Pacific Islander',
  'Other',
];

export const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widowed'];
