import React from "react";
import { useParams } from "react-router-dom";
import HydraplayBot from "./HydraplayBot";

export const HydraplayBotWrapper = () => {
	// Extract the 'botid' parameter from the URL
	const { botid } = useParams();

	// Pass the 'botid' as a prop to the HydraplayBot component
	return <HydraplayBot retailer={botid} />;
};
