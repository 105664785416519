import React, { Component } from "react";
import { View, Text } from "react-native";

export default class AppTermsAndConditions extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let fs = 11;
		return (
			<View>
				<Text
					style={{
						color: "#000",
						fontSize: fs,
					}}>
					Disclaimer: Skincare Advice Chatbot Terms and Conditions
				</Text>

				<Text
					style={{
						color: "#000",
						marginVertical: 4,
						fontSize: fs,
					}}>
					{`Introduction
1.1. The Skincare Advice Chatbot (referred to as "the Chatbot") is an automated service designed to provide general skincare advice and recommendations.
1.2. The Chatbot is not intended to provide medical advice, diagnosis, or treatment. Any information provided by the Chatbot should not be considered a substitute for professional medical advice.

Use of Information
2.1. Users of the Chatbot acknowledge that the advice provided is based on general skincare principles and may not be suitable for all individuals.
2.2. The Chatbot's recommendations are not personalized medical advice and do not take into account individual medical conditions, allergies, sensitivities, or other factors that could affect skincare suitability.

Limitation of Liability
3.1. The creators and operators of the Chatbot shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of the use of or reliance on the information provided by the Chatbot.
3.2. Users are solely responsible for any decisions they make based on the information provided by the Chatbot.

No Medical Relationship
4.1. The use of the Chatbot does not establish a medical professional-patient relationship between the user and the creators/operators of the Chatbot.
4.2. Users with medical concerns or specific skincare conditions are encouraged to consult with a qualified healthcare professional before following any advice provided by the Chatbot.

Accuracy of Information
5.1. While efforts are made to ensure the accuracy and reliability of the information provided by the Chatbot, no guarantee is made regarding the completeness, accuracy, or reliability of such information.

Acceptance of Terms
6.1. By using the Chatbot, users acknowledge that they have read, understood, and agreed to these terms and conditions.
6.2. These terms and conditions may be updated or modified from time to time without prior notice.

Contact Information
7.1. For questions or concerns regarding these terms and conditions or the operation of the Chatbot, please contact monique.naicker@revlon.com.`}
				</Text>
			</View>
		);
	}
}
