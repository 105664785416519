import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	SafeAreaView,
	TextInput,
	Image,
} from "react-native";
import AutoHeightImage from "../AutoHeightImageComp";
import moment from "moment";
// import { APP_COLOURS } from "../APP_VARS";
import HydraplayQuiz from "./Quiz";
import "./quiz.css";

export default class QuizFrame extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// flex: 1,
					width: window.innerWidth,
					height: window.innerHeight,
				}}>
				{/* <Image
					source={require("./Background.jpg")}
					style={{
						// width: window.innerWidth,
						// height: window.innerHeight,
						width: "100%", // or specify a fixed width like width: 500
						height: "100%", // or specify a fixed height like height: 300
						resizeMode: "cover",
						position: "absolute",
						top: 0,
					}}
				/> */}
				<div className='bg-image'></div>

				<View
					style={{
						zIndex: 99,
						position: "absolute",
						top: 0,
						left: 0,
						padding: 10,
					}}>
					<HydraplayQuiz />
				</View>
			</View>
		);
	}
}
