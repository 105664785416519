import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	SafeAreaView,
	TextInput,
	Platform,
	UIManager,
	LayoutAnimation,
	Keyboard,
	TouchableWithoutFeedback,
	Animated,
	Easing,
	Image,
} from "react-native";
import AutoHeightImage from "./AutoHeightImageComp";
import moment from "moment";
import { APP_COLOURS } from "./APP_VARS";
import CookieConsent from "react-cookie-consent";
// import {api_chatmessages, api_getChatMessages} from '../Api';
import {
	crudUpdate,
	crudCreate,
	crudReadAll,
	sendSingleJarvisMessage,
	sendJarvisMessage,
} from "./CRUDHELPER";
import ExpandableTextInput from "./ExpandableInput";
import AppTermsAndConditions from "./AppTermsAndConditions";

import "./Chat.css";

function uuid() {
	return "xxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class JuliaChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: [
				// {
				// 	text: "Hi there, How can I help you with your skincare today? ",
				// 	from: "Julia",
				// 	date: "2024-07-04T13:35:11.553Z",
				// },
			],

			messageData: "",
			messageDataType: "text",
			beginScroll: false,
			secondsCountdown: 10,

			bottomofScrollview: 0,
			listeningForMessage: false,

			interaction_id: uuid(),

			getStarted: false,

			height: window.innerHeight,
		};
	}

	triggerTimer = async () => {
		//  return;
		console.log("triggerTimer");
		await clearInterval(this.interval);
		await this.setState({
			secondsCountdown: 10,
		});

		this.interval = await setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.secondsCountdown === 1) {
			await console.log("fetch messages");
			await this.setState({
				secondsCountdown: 0,
			});
			await clearInterval(this.interval);
			// await this.getMessages();
		}
		console.log(this.state.secondsCountdown);
	}

	getMessages = async () => {
		await clearInterval(this.interval);
		crudReadAll("pm_Tyron_chats", async (s, d) => {
			console.log(d, s);
			await this.setState({
				messages: d,
				loading: false,
				sendingMessage: false,
			});

			//   this.onValueChange(
			//     this.props.chat_id,
			//     JSON.stringify(responseData),
			//   );
		});

		await this.triggerTimer();
	};

	s_SingleJuliaMessage = async () => {
		let now = moment().toISOString();

		let message = {
			text: this.messageData,
			from: "Human",
			date: now,
			interaction_id: this.state.interaction_id,
		};

		// TTS.speak('Good day Sir!', {
		//   iosVoiceId: 'com.apple.ttsbundle.Samantha-compact',
		//   rate: 0.52,
		// });

		// return;

		// let message = {
		//   text: 'How long until chrsitmas',
		//   from: 'Human',
		//   sent: now,
		// };
		await clearInterval(this.interval);
		await this.setState({
			sendingMessage: true,
			messageData: this.messageData,
		});
		let messages = this.state.messages;
		messages = [...this.state.messages, message];

		this.setState({
			messages,
		});

		this.scrollView.scrollToEnd({ animated: true });
		// this.getMessages();
		await this.scrollView.scrollToEnd({ animated: true });
		await this.inputRef.clear();
		await this.setState({
			messageData: "",
			sendingMessage: false,
		});
		this.scrollView.scrollToEnd({ animated: true });

		let messageString = "";
		const mm = await messages.map(
			(m) =>
				(messageString = `${messageString} ${m.date} ${m.from}: ${m.text};`),
		);
		this.setState({
			listeningForMessage: true,
		});
		console.log(messageString);
		let postOb = {
			messageString,
			interaction_id: this.state.interaction_id,
		};
		// return;
		sendSingleJarvisMessage(postOb, async (s, d) => {
			if (s === 200 && d.length > 0) {
				messages = [...messages, d[0]];
				console.log(s, d);
				// TTS.speak(d[0].text, {
				//   iosVoiceId: 'com.apple.ttsbundle.Daniel-compact',
				//   name: 'Samantha',
				//   language: 'en-GB',
				//   rate: 0.55,
				// });
				this.setState({
					messages,
					listeningForMessage: false,
				});
			}
			// this.scrollView.scrollToEnd({animated: true});
		});
	};

	async componentDidMount() {
		// await this.getMessages();
		await clearInterval(this.interval);
		this.interval = setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
		console.log("mounted");
		// Add event listeners for focus and blur events
		document.addEventListener("focusin", this.handleFocusIn);
		document.addEventListener("focusout", this.handleFocusOut);
	}

	async componentWillUnmount() {
		await console.log("unmounted");
		await clearInterval(this.interval);

		this.setState = (state, callback) => {
			return;
		};
		// Remove event listeners when the component is unmounted
		document.removeEventListener("focusin", this.handleFocusIn);
		document.removeEventListener("focusout", this.handleFocusOut);
	}

	handleFocusIn = (e) => {
		if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
			const scrollPosition = window.pageYOffset;
			this.setState({ scrollPosition });

			document.body.style.position = "fixed";
			document.body.style.top = `-${scrollPosition}px`;
			document.body.classList.add("keyboard-visible");
		}
	};

	handleFocusOut = (e) => {
		if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
			const { scrollPosition } = this.state;

			document.body.style.position = "";
			document.body.style.top = "";
			window.scrollTo(0, scrollPosition);

			setTimeout(() => {
				document.body.classList.remove("keyboard-visible");
			}, 300); // Delay to wait for keyboard to disappear
		}
	};

	render() {
		let messages = this.state.messages.sort(
			(a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
		);
		if (this.state.getStarted) {
			return (
				<div className='chat'>
					<View
						style={{
							// flex: 1,
							// backgroundColor: APP_COLOURS.BACKGROUND,
							backgroundColor: APP_COLOURS.OFFWHITE,
							// paddingTop: 70,
							// paddingBottom: 17,
							// height: window.innerHeight
							// height: "100%",
							// width: window.innerWidth,
							// height: this.state.height,
							// overflow: "hidden",

							// overflow: "hidden",
							// width: window.innerWidth,
							// height: window.innerHeight,
						}}>
						<Image
							source={require("./EA Beauty Bot-06.png")}
							style={{
								width: window.innerWidth,
								height: this.state.height,
								// width: "100%", // or specify a fixed width like width: 500
								// height: "100%", // or specify a fixed height like height: 300
								resizeMode: "cover",
								position: "absolute",
								top: 0,
							}}
						/>
						<View
							style={{
								flexDirection: "row",
								backgroundColor: APP_COLOURS.BACKGROUND,
								shadowColor: APP_COLOURS.BACKGROUND,
								shadowOffset: { width: 0, height: 8 },
								shadowOpacity: 0.14,
								shadowRadius: 9.32,
								elevation: 12,
								// top: this.state.keyboardActive ? 48 : 100,
								position: "absolute",
								width: "100%",
								top: 0,
								display: "none",
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									alignItems: "center",
									// paddingRight: 5,
								}}>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										margin: 5,
										borderRadius: 30,
										borderWidth: 2,
										padding: 0.5,
										backgroundColor: "#FFF",
										// borderColor: "online",
										overflow: "hidden",
										marginRight: 9,
									}}>
									<AutoHeightImage
										width={40}
										source={require("./EA Beauty Bot-05.png")}
										style={{
											resizeMode: "contain",
											borderRadius: 25,
										}}
									/>

									{this.state.listeningForMessage ? (
										<View
											style={{
												padding: 10,
												justifyContent: "center",
												alignItems: "center",
												position: "absolute",
												top: 0,
												left: 0,
											}}>
											<ActivityIndicator
												color={APP_COLOURS.YELLOW}
											/>
										</View>
									) : null}
								</View>

								<View
									style={
										{
											// flex: 1,
										}
									}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											//   marginBottom: 3,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontWeight: "700",
												fontSize: 15,
												color: "#FFF",
											}}>
											Julia
										</Text>
									</View>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontWeight: "200",
												fontSize: 10,
												color: "#FFF",
											}}>
											- Virtual Beauty Expert
										</Text>
									</View>
								</View>
							</View>
						</View>

						<View
							style={{
								position: "absolute",
								top: 200,
								marginHorizontal: "auto",
								width: "100%",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("./ea_logo.svg").default}
								style={{
									width: 200,
									height: 200,
									resizeMode: "contain",
								}}
							/>

							{/* {this.state.listeningForMessage ? (
						<View
							style={{
								padding: 10,
								justifyContent: "center",
								alignItems: "center",
								position: "absolute",
								top: 0,
								left: 0,
							}}>
							<ActivityIndicator color={APP_COLOURS.YELLOW} />
						</View>
					) : null} */}
						</View>

						<View
							style={{
								width: window.innerWidth,
								height: this.state.height - 76,

								// height: window.innerHeight,
								// minHeight
								// backgroundColor: APP_COLOURS.WHITE_TRANSPARENT9,
								// paddingTop: 60,
								// height: this.state.height - 50,
							}}>
							<ScrollView
								ref={(ref) => (this.scrollView = ref)}
								keyboardShouldPersistTaps='always'
								// onScrollBeginDrag={() =>
								//   this.setState({
								//     beginScroll: true,
								//   })
								// }
								// onScrollEnd={() =>
								//   this.setState({
								//     beginScroll: false,
								//   })
								// }
								// onScrollBeginDrag={() => Keyboard.dismiss()}
								onContentSizeChange={() =>
									this.scrollView.scrollToEnd({
										animated: true,
									})
								}
								scrollEventThrottle={16}
								onScroll={(e) => {
									//   // let this.state.coords
									let yPos = e.nativeEvent.contentOffset.y;

									// if (
									// 	yPos >=
									// 		this.state.bottomofScrollview -
									// 			screenHeight &&
									// 	this.state.beginScroll
									// ) {
									// 	this.setState({
									// 		beginScroll: false,
									// 	});
									// }

									//   Animated.parallel([
									//     Animated.spring(this.scrollPosition, {
									//       toValue: yPos,
									//       useNativeDriver: false,
									//       friction: 12,
									//     }),
									//     Animated.spring(this.isScrolling, {
									//       toValue: 1,
									//       useNativeDriver: false,
									//       friction: 12,
									//     }),
									//     Animated.spring(this.yOffset, {
									//       toValue: Math.round(progresss),
									//       useNativeDriver: false,
									//       friction: 12,
									//     }),
									//   ]).start();
								}}>
								<TouchableWithoutFeedback
									onPress={() => {
										// Keyboard.dismiss();
										this.setState({
											keyboardActive: false,
										});
									}}>
									<View
										onLayout={(e) => {
											console.log();
											// this.setState({
											// 	bottomofScrollview:
											// 		e.nativeEvent.layout.height,
											// });
										}}
										style={{
											// flex: 1,
											// width: "100%",
											padding: 10,
											paddingRight: 10,
											paddingLeft: 15,
											// minHeight: window.innerHeight,
											marginTop: 30,
										}}>
										{messages.map((d, i) => {
											return (
												<TouchableOpacity
													activeOpacity={1}
													onPress={() => {}}>
													<View
														key={`js-${i}`}
														style={{
															width: "100%",
															zIndex: 10,
															alignItems:
																d.from ===
																"Human"
																	? "flex-end"
																	: "flex-start",

															marginTop:
																d.from ===
																"Human"
																	? 15
																	: 0,
														}}>
														<View
															style={{
																maxWidth: "75%",
																flexDirection:
																	d.length >
																		20 ||
																	d.from !==
																		"Jarvis"
																		? "column"
																		: "row",
																backgroundColor:
																	d.from ===
																	"Human"
																		? APP_COLOURS.BLUE2
																		: "#FFF",
																borderRadius: 16,
																borderTopLeftRadius:
																	d.from ===
																	"Jarvis"
																		? 16
																		: 3,
																borderBottomLeftRadius:
																	d.from ===
																	"Jarvis"
																		? 16
																		: typeof messages[
																				i +
																					1
																		  ] !==
																		  "undefined"
																		? messages[
																				i +
																					1
																		  ]
																				.from ===
																		  messages[
																				i
																		  ].from
																			? 3
																			: 16
																		: 16,
																borderTopRightRadius:
																	d.from ===
																	"Jarvis"
																		? typeof messages[
																				i -
																					1
																		  ] !==
																		  "undefined"
																			? messages[
																					i -
																						1
																			  ]
																					.from ===
																			  messages[
																					i
																			  ]
																					.from
																				? 3
																				: 16
																			: 3
																		: 16,
																borderBottomRightRadius:
																	d.from ===
																	"Human"
																		? 2
																		: 16,
																padding: 10,
																marginTop:
																	i > 0
																		? messages[
																				i -
																					1
																		  ]
																				.from ===
																		  messages[
																				i
																		  ].from
																			? 3
																			: 15
																		: 3,
																shadowColor:
																	"#000",
																shadowOffset: {
																	width: 0,
																	height: 8,
																},
																shadowOpacity:
																	d.from ===
																	"Human"
																		? 0
																		: 0.24,
																shadowRadius: 9.32,
																elevation: 10,
																// opacity: 0.9,
															}}>
															<Text
																style={{
																	color:
																		d.from ===
																		"Human"
																			? "#FFF"
																			: "#000",
																	fontSize: 17,
																	marginBottom: 20,
																}}>
																{typeof d.text !==
																"undefined"
																	? d.text
																	: d.data}
															</Text>
															<Text
																style={[
																	{
																		fontWeight:
																			"300",
																		fontStyle:
																			"italic",
																		marginLeft: 10,
																		color:
																			d.from ===
																			"Human"
																				? "#FFF"
																				: "#000",
																		fontSize: 10,
																		alignSelf:
																			"flex-end",
																		marginTop: 2,
																		opacity: 0.7,
																	},
																	d.from !==
																	"Human"
																		? {
																				marginRight: 50,
																				position:
																					"absolute",
																				right: 5,
																				bottom: 10,
																		  }
																		: {},
																]}>
																{moment().diff(
																	d.createdAt,
																	"days",
																) >= 1
																	? moment(
																			d.createdAt,
																	  ).format(
																			"HH:mm MMM DD, YYYY",
																	  )
																	: moment(
																			d.createdAt,
																	  ).format(
																			"HH:mm",
																	  )}
															</Text>

															<View
																style={{
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																	margin: 5,
																	borderRadius: 30,
																	// borderWidth: 2,
																	padding: 0.5,
																	backgroundColor:
																		"#FFF",
																	// borderColor: "online",
																	overflow:
																		"hidden",
																	marginRight: 9,
																	position:
																		"absolute",
																	right: 0,
																	bottom: -25,

																	display:
																		d.from ===
																		"Human"
																			? "none"
																			: "flex",
																}}>
																<AutoHeightImage
																	width={40}
																	source={require("./EA Beauty Bot-05.png")}
																	style={{
																		resizeMode:
																			"contain",
																		borderRadius: 25,
																	}}
																/>

																{this.state
																	.listeningForMessage ? (
																	<View
																		style={{
																			padding: 10,
																			justifyContent:
																				"center",
																			alignItems:
																				"center",
																			position:
																				"absolute",
																			top: 0,
																			left: 0,
																		}}>
																		<ActivityIndicator
																			color={
																				APP_COLOURS.YELLOW
																			}
																		/>
																	</View>
																) : null}
															</View>
															{/* <Image
                          source={require('../assets/images/Checkmark.png')}
                          style={{
                            width: 12,
                            height: 12,
                            resizeMode: 'contain',
                            position: 'absolute',
                            right: 0,
                            bottom: -12,
                            display:
                              d.from === "Human" ? 'flex' : 'none',
                          }}
                        /> */}
														</View>
													</View>
												</TouchableOpacity>
											);
										})}
									</View>
								</TouchableWithoutFeedback>
							</ScrollView>
						</View>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
								paddingLeft: 10,
								marginTop: 5,
								paddingRight: 10,
								paddingBottom: 10,

								position: "absolute",
								// bottom: 20,
								bottom: 0,
								left: 0,
								width: "100%",
								zIndex: 999,
								height: 50,
							}}>
							<TextInput
								ref={(ref) => (this.inputRef = ref)}
								style={{
									flex: 1,

									paddingLeft: 15,
									maxHeight: 300,
									padding: 12,
									paddingTop: 12,
									fontSize: 16,
									borderRadius: 17,
									paddingRight: 50,
									// marginRight: 10,
									// marginLeft: this.state.keyboardActive
									// 	? 3
									// 	: 15,
									color: "#000",
									// backgroundColor: APP_COLOURS.BG4,
									backgroundColor: "#FFF",
									fontWeight: "400",
									// borderWidth: 0.5,
									// borderColor: "#828282",

									shadowColor: APP_COLOURS.BACKGROUND,
									shadowOffset: {
										width: 0,
										height: 8,
									},
									shadowOpacity: 0.14,
									shadowRadius: 9.32,
									elevation: 12,
								}}
								multiline
								// numberOfLines={1}
								placeholderTextColor={"#828282"}
								clearButtonMode='while-editing'
								autoCompleteType='off'
								autoCorrect={true}
								autoCapitalize='sentences'
								placeholder={" Type a message"}
								onFocus={async () => {
									await this.setState({
										keyboardActive: true,
										beginScroll: false,
									});

									setTimeout(() => {
										this.scrollView.scrollToEnd({
											animated: true,
										});
										this.scrollView.scrollToEnd({
											animated: true,
										});
									}, 600);
								}}
								onBlur={() =>
									this.setState({
										keyboardActive: false,
									})
								}
								// value={this.state.messageData}
								onChangeText={(text) => {
									this.messageData = text;
								}}
							/>
							{/* <ExpandableTextInput
						onChangeText={(text) => (this.messageData = text)}
					/> */}
							<View
								style={{
									position: "absolute",
									marginRight: 5,
								}}>
								<TouchableOpacity
									disabled={this.state.sendingMessage}
									style={{
										opacity:
											this.state.filterSearch !== ""
												? 1
												: 0.5,
									}}
									onPress={async () => {
										// await this.sendMessage();
										this.s_SingleJuliaMessage();
									}}>
									<View
										style={{
											width: 35,
											height: 35,
											justifyContent: "center",
											alignItems: "center",
										}}>
										{this.state.sendingMessage ? (
											<ActivityIndicator
												color={APP_COLOURS.BLUE2}
												size='small'
											/>
										) : (
											<AutoHeightImage
												width={24}
												source={require("./EA Beauty Bot-07.png")}
											/>
										)}
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</div>
			);
		} else {
			return (
				<View
					style={{
						// flex: 1,
						// backgroundColor: APP_COLOURS.BACKGROUND,
						backgroundColor: APP_COLOURS.OFFWHITE,
						// paddingTop: 70,
						// paddingBottom: 17,
						// height: window.innerHeight

						overflow: "hidden",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<Image
						source={require("./EA Beauty Bot-06.png")}
						style={{
							// width: window.innerWidth,
							// height: window.innerHeight,
							width: "100%", // or specify a fixed width like width: 500
							height: "100%", // or specify a fixed height like height: 300
							resizeMode: "cover",
							position: "absolute",
							top: 0,
						}}
					/>

					<Image
						source={require("./Assets-09.png")}
						style={{
							width: 400,
							height: 400,
							resizeMode: "contain",
							position: "absolute",
							bottom: 0,
							right: -100,
						}}
					/>
					<View
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							top: 0,
							left: 0,
							padding: 20,
							paddingTop: 60,
							// justifyContent: "center",
							// alignItems: "center",
							maxWidth: 350,
						}}>
						<View style={{ paddingLeft: 10 }}>
							<Image
								source={require("./ea_logo.svg").default}
								style={{
									width: 160,
									height: 45,
									resizeMode: "contain",
									marginBottom: 10,
								}}
							/>
							<Text
								style={{
									color: "#000",
									fontSize: 28,
									fontFamily: "Gotham2",
									fontWeight: "800",
								}}>
								YOUR VIRTUAL BEAUTY EXPERT
							</Text>

							<Text
								style={{
									color: "#000",
									fontSize: 16,
									fontFamily: "Gotham",
									fontWeight: "book",
								}}>
								Introducing your virtual skin care expert who
								can recommend products and personalised regimen
								specifically suited to your skin.
							</Text>

							<TouchableOpacity
								style={{}}
								onPress={async () => {
									this.setState({
										termsandconditions: true,
									});
								}}>
								<Text
									style={{
										color: "#000",
										// color: "blue",
										fontSize: 10,
										fontFamily: "Gotham",
										fontWeight: "book",
										marginVertical: 20,
										opacity: 0.5,
									}}>
									terms and conditions
								</Text>
							</TouchableOpacity>
						</View>
						<TouchableOpacity
							style={{
								marginTop: 20,
							}}
							onPress={async () => {
								this.setState({
									getStarted: true,
								});

								setTimeout(
									() =>
										this.setState({
											messages: [
												{
													text: "Hi there, How can I help you with your skincare today? ",
													from: "Julia",
													date: "2024-07-04T13:35:11.553Z",
												},
											],
										}),
									400,
								);
							}}>
							<View
								style={{
									width: "60%",
									// height: 35,
									padding: 10,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "#000",
									borderRadius: 15,
								}}>
								<Text
									style={{
										color: "#FFF",
										fontSize: 16,
										fontFamily: "Gotham",
										fontWeight: "book",
									}}>
									Get started!
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					{this.state.termsandconditions ? this.tandcModal() : null}
					{this.cookieConcent()}
				</View>
			);
		}
	}

	tandcModal = () => {
		let screenHeight = window.innerHeight;
		let screenWidth = window.innerWidth;

		screenWidth =
			screenWidth >= this.props.mw ? this.props.mw : screenWidth;
		return (
			<View
				style={{
					flex: 1,
					position: "absolute",
					zIndex: 999,
					backgroundColor: "rgba(52, 52, 52, 0.8)",
					width: screenWidth,
					height: screenHeight,
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						padding: 10,
					}}>
					<View
						style={{
							width: "100%",
							height: screenHeight - 120,
							backgroundColor: "#FFF",
							borderRadius: 20,
							// padding: 10,
							justifyContent: "center",
							alignItems: "center",
							shadowColor: "#999797",
							shadowOffset: {
								width: 0,
								height: 4,
							},
							shadowOpacity: 0.3,
							shadowRadius: 4.65,
							elevation: 8,
						}}>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "auto",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 19,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
								}}>
								Disclaimer
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 15,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
								}}>
								Skincare Advice Chatbot Terms and Conditions
							</Text>
						</View>
						<View
							style={{
								flex: 1,
								width: "100%",
							}}>
							<ScrollView>
								<View
									style={{
										padding: 10,
									}}>
									<AppTermsAndConditions />
								</View>
							</ScrollView>
						</View>
						<View
							style={{
								width: "100%",
								padding: 10,
							}}>
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										termsandconditions: false,
									});
								}}>
								<View
									style={{
										width: "100%",
										padding: 10,
										borderRadius: 10,
										backgroundColor: "#000",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											color: "#FFF",
											fontWeight: "300",
											fontSize: 16,
										}}>
										Done
									</Text>
								</View>
							</TouchableOpacity>

							{/* <TouchableOpacity
								onPress={() => {
									this.setState({
										applyNow: false,
									});
								}}>
								<View
									style={{
										width: "100%",
										padding: 10,
										borderRadius: 10,
										// backgroundColor: '#000',
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											color: "#000",
											fontWeight: "300",
											fontSize: 16,
										}}>
										cancel
									</Text>
								</View>
							</TouchableOpacity> */}
						</View>
					</View>
				</View>
			</View>
		);
	};

	cookieConcent = () => {
		return (
			<CookieConsent
				location='bottom'
				buttonText='Accept & continue'
				cookieName='CookieNotice'
				style={{
					background: APP_COLOURS.BG2,
					backgroundColor: APP_COLOURS.BLUE,
				}}
				buttonStyle={{
					color: "#FFF",
					fontSize: "16px",
					borderRadius: 10,
					padding: 10,
					// backgroundColor: APP_COLOURS.BLUE,
					backgroundColor: "#000",
					borderRadius: 10,
				}}
				expires={150}>
				<View
					style={{
						flexDirection: "row",
					}}>
					{/* <View
						style={{
							alignItems: "flex-start",
							// marginBottom: 40,
							marginRight: 15,
						}}>
						<Logo size={50} />
					</View> */}
					<Text
						style={{
							color: "#FFF",
							maxWidth: 600,
						}}>
						We use cookies to make your experience of our websites
						better. By using and further navigating this website you
						accept this. Detailed information about the use of
						cookies on this website is available by clicking on{" "}
						<TouchableOpacity
							style={{
								color: "#FFF",
								fontWeight: "600",
							}}
							onPress={() => {
								window.open("/julia/cookiepolicy", "blank");
							}}>
							<Text
								style={{
									color: "#FFF",
									maxWidth: 600,
								}}>
								more information.{" "}
							</Text>
						</TouchableOpacity>
					</Text>
				</View>
			</CookieConsent>
		);
	};
}
