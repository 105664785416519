import moment from "moment";
import {
	api_crudCreate,
	api_crudRead,
	api_crudDelete,
	api_crudUpdate,
	api_getUsers,
	api_crudWithImage,
	api_crudDeleteDocument,
	api_sendBasicEmail,
} from "./Api";

// THIS FILE IS to get and set async data of the device without making too many requests

async function getUserData() {
	try {
		const value = await localStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}

export const crudCreate = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithBase64 = async (collection, where, next) => {
	let url = api_crudWithImage;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: collection,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteDocument = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// // logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudRead = async (collection, where, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadAll = async (collection, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: "",
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then(async (response) => {
			// console.log(response);
			if (response.status === 200) {
				await response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// // logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdate = async (collection, obj, next) => {
	let url = api_crudUpdate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				// // logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDelete = async (collection, obj, next) => {
	let url = api_crudDelete;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			if (response.status !== 200) {
				// // logOut();
				return;
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//

export const crudSendEmail = async (sender, recipient, subject, body) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendBasicEmail;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		sender: sender,
		recipient: recipient,
		body: `${body}`,
		subject: `${subject}`,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response.status);
			if (response.status !== 200) {
				alert("Error sending email!");
			} else if (response.status === 200) {
				alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

//
//

//
//

export const sendJarvisMessage = async (next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/fe2f55da-b6b5-4cc6-8523-aa05cb3b4382";
	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer 1234`,
		},
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {})
					.catch((error) => {});
			} else {
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendSingleJarvisMessage = async (message, next) => {
	// let url =
	// 	"https://workflow.tetrice.co.za/webhook/fe2f55da-b6b5-4cc6-8523-aa05cb3b4382";

	// let url =
	//   'https://workflow.tetrice.co.za/webhook-test/fe2f55da-b6b5-4cc6-8523-aa05cb3b4382';

	// let url =
	// 	"https://workflow.tetrice.co.za/webhook/d004b126-1e8d-4767-9555-2ed073dc51ab";

	let url =
		"https://workflow.tetrice.co.za/webhook/97223807-faa9-4cb3-9b0e-2b836fb5481f";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer 1234`,
		},
		redirect: "follow",
		body: JSON.stringify(message),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, [
							{
								text: "Error",
								date: moment().toISOString(),
								from: "Jarvis",
							},
						]);
					});
			} else {
				return next(response.status, [
					{
						text: "Error",
						date: moment().toISOString(),
						from: "Jarvis",
					},
				]);
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendHydraplayJuliaMessage = async (message, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/e12937ca-1090-4a45-8c02-33709e3e2811";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer 1234`,
		},
		redirect: "follow",
		body: JSON.stringify(message),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, [
							{
								text: "Error",
								date: moment().toISOString(),
								from: "Jarvis",
							},
						]);
					});
			} else {
				return next(response.status, [
					{
						text: "Error",
						date: moment().toISOString(),
						from: "Jarvis",
					},
				]);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadGoogleCalendar = async (collection, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/e5817590-8d97-4c54-9ff7-77d494e10029";
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: "",
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then(async (response) => {
			// console.log(response);
			if (response.status === 200) {
				await response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// Actions.replace('Unauthed');
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetEmailLogs = async (next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/3f5faac3-9afa-435c-9a4e-d2cc5c467ad7";
	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},

		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then(async (response) => {
			// console.log(response);
			if (response.status === 200) {
				await response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// Actions.replace('Unauthed');
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeletEmailLog = async (row, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/3f5faac3-9afa-435c-9a4e-d2cc5c467ad7";
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			row_number: row,
		}),

		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then(async (response) => {
			// console.log(response);
			if (response.status === 200) {
				await response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// Actions.replace('Unauthed');
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};
