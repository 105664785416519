import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image as AutoHeightImage,
	Dimensions,
	ActivityIndicator,
	ScrollView,
	Image,
} from "react-native";
import { APP_COLOURS } from "./APP_VARIABLES";
import RoutinePage from "./Routine";
import JournalCard from "./JournalCard";
import moment from "moment";

export default class ChallengeApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: localStorage.getItem("uuid"),

			days: [
				{ title: "Day 1", data: [] },
				{ title: "Day 2", data: [] },
				{ title: "Day 3", data: [] },
				// { title: "Day 4", data: [] },
				// { title: "Day 5", data: [] },
				// { title: "Day 6", data: [] },
				// { title: "Day 7", data: [] },
			],
			completedDays: [
				{
					title: "Day 1",
					data: [],
					date: moment().subtract(1, "day"),
				},
			],
			// completedDays: [],
		};
	}

	render() {
		const { days, completedDays } = this.state;
		let screenWidth = window.innerWidth;
		let screenHeight = window.innerHeight;
		return (
			<View
				style={{
					// flex: 1,
					// backgroundColor: "red",
					overflow: "hidden",
					height: window.innerHeight,
					width: window.innerWidth,
					paddingVertical: 1,

					backgroundColor: "#FFF",
				}}>
				<View
					style={{
						position: "absolute",
						top: 0,
					}}>
					<Image
						style={{
							width: screenWidth,
							height: screenHeight,
							resizeMode: "cover",
						}}
						source={require("../assets/images/bg2.png")}
					/>
				</View>
				<View
					style={{
						paddingTop: 50,
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",

						zIndex: 500,
						backgroundColor: "#FFF",
						// backgroundColor: APP_COLOURS.BACKGROUND_FADE,
						shadowColor: "#999797",
						shadowOffset: {
							width: 0,
							height: 4,
						},
						shadowOpacity: this.state.isscrolling ? 0.3 : 0,
						shadowRadius: 4.65,
						elevation: 8,

						padding: 10,
						paddingLeft: 15,
					}}>
					<View style={{ flex: 1, justifyContent: "center" }}>
						<AutoHeightImage
							style={{
								width: 200,
								height: 50,
								resizeMode: "contain",
							}}
							source={require("./ea_logo1.png")}
						/>
					</View>

					<TouchableOpacity activeOpacity={1} onPress={() => {}}>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "center",
								// alignItems: 'center',
							}}>
							<View
								style={{
									width: 45,
									height: 45,
									borderRadius: 19,
									borderWidth: 5,
									justifyContent: "center",
									alignItems: "center",
									borderColor: "#FFF",
									backgroundColor: "#FFF",
									// overflow: 'hidden',
									top: 0,
									left: 0,
									shadowcolor: APP_COLOURS.PRIMARY3,
									shadowOffset: {
										width: 0,
										height: 4,
									},
									shadowOpacity: 0.23,
									shadowRadius: 4.65,
									elevation: 8,
								}}>
								<View
									style={{
										overflow: "hidden",
										borderRadius: 19,
									}}>
									{/* <AutoHeightImage
                    width={40}
                    source={require('../assets/images/ashley.jpeg')}
                    style={{
                      resizeMode: 'cover',
                    }}
                  /> */}
								</View>
							</View>
							<View
								style={{
									width: 45,
									height: 45,
									borderRadius: 19,
									borderWidth: 5,
									borderColor: "#FFF",
									backgroundColor: "transparent",
									position: "absolute",
									overflow: "hidden",
									top: 0,
									left: 0,
									shadowcolor: APP_COLOURS.PRIMARY3,
									shadowOffset: {
										width: 2,
										height: 8,
									},
									shadowOpacity: 0.13,
									shadowRadius: 4.65,
									elevation: 8,
								}}
							/>
						</View>
					</TouchableOpacity>
				</View>
				<ScrollView>
					{/* <View
						style={{
							width: "100%",
						}}>
						<View
							style={{
								// height: 100,
								width: "100%",
								backgroundColor: "#FFF",
							}}>
							<AutoHeightImage
								source={require("./challenge_bannerOnl.png")}
								style={{
									width: window.screen.width,
									height: window.screen.width * (374.4 / 772),
									resizeMode: "contain",
								}}
							/>
						</View>

						<View
							style={{
								flexDirection: "row",
								width: "100%",
								padding: 10,
							}}>
							<View
								style={{
									flex: 1,
									backgroundColor: "#FFF",
									borderRadius: 12,
									marginBottom: 10,
									padding: 10,
									marginRight: 10,
								}}>
								<h3>What to do</h3>
							</View>
							<View
								style={{
									flex: 1,
									backgroundColor: "#FFF",
									borderRadius: 12,
									marginBottom: 10,
									marginRight: 10,
									padding: 10,
								}}>
								<h3>When to do it</h3>
							</View>
						</View>
					</View> */}

					<View
						style={{
							flex: 1,
							padding: 10,
							maxWidth: 400,
							width: "100%",
							marginLeft: "auto",
							marginRight: "auto",
						}}>
						{days
							.filter((d_) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === d_.title,
								);
								if (isCompleted) {
									return d_;
								} else {
									{
										/* return d_; */
									}
								}
							})
							.map((day, index) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === day.title,
								);
								const opacity = isCompleted ? 0.5 : 1;

								return (
									<View
										key={index}
										style={{
											width: "100%",
											backgroundColor: "#FFF",
											borderRadius: 12,
											marginBottom: 10,
											padding: 10,
											// opacity,
										}}>
										{/* <Text>{day.title}</Text> */}
										<JournalCard data={day} />
									</View>
								);
							})}

						{days
							.filter((d_) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === d_.title,
								);
								if (isCompleted) {
								} else {
									return d_;
								}
							})
							.slice(0, 1)
							.map((day, index) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === day.title,
								);
								const opacity = isCompleted ? 0.5 : 1;

								return (
									<View
										key={index}
										style={{
											width: "100%",
											backgroundColor: "#FFF",
											borderRadius: 12,
											marginBottom: 10,
											padding: 10,
											opacity,
										}}>
										<Text>{day.title}</Text>
										<TouchableOpacity
											onPress={() => {
												// window.location = "./app";
												// const set_ = localStorage.setItem(
												// 	"gotoapp",
												// 	true,
												// );
											}}>
											<View
												style={{
													padding: 10,
													borderWidth: 1,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "#FFF",
													borderRadius: 10,
													marginBottom: 10,
													marginTop: 40,
													display: !isCompleted
														? "flex"
														: "none",
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontSize: 14,
														fontWeight: "300",
													}}>
													Start {day.title}
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								);
							})}

						{days
							.filter((d_) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === d_.title,
								);
								if (isCompleted) {
								} else {
									return d_;
								}
							})
							.slice(1, 10)
							.map((day, index) => {
								const isCompleted = completedDays.some(
									(completedDay) =>
										completedDay.title === day.title,
								);
								const opacity = isCompleted ? 0.5 : 1;

								return (
									<View
										key={index}
										style={{
											width: "100%",
											backgroundColor: "#FFF",
											borderRadius: 12,
											marginBottom: 10,
											padding: 10,
											opacity,
										}}>
										<Text>{day.title}</Text>
										{/* <JournalCard data={day} /> */}
									</View>
								);
							})}

						{/* <Text>WHat to do</Text> */}
					</View>

					<View
						style={{
							padding: 15,
							opacity: 0.7,
							marginTop: 30,

							maxWidth: 400,
							width: "100%",
							marginLeft: "auto",
							marginRight: "auto",
						}}>
						<Text
							style={{
								fontSize: 16,
								fontWeight: "300",
								fontFamily: "Avenir",
								bottom: -3,
							}}>
							Discover
						</Text>
						<Text
							style={{
								fontSize: 25,
								fontWeight: "700",
								fontFamily: "Avenir",
							}}>
							Ceramides
						</Text>
					</View>
					<TouchableOpacity onPress={() => {}}>
						<View
							style={{
								flexDirection: "row",
								// width: "100%",
								// marginBottom: 10,
								padding: 10,
							}}>
							<AutoHeightImage
								style={{
									width: screenWidth - 20,
									// height:
									height: 80,
									resizeMode: "contain",
									shadowcolor: APP_COLOURS.BACKGROUND,
									shadowOffset: {
										width: 2,
										height: 5,
									},
									shadowOpacity: 0.07,
									shadowRadius: 4.65,
									elevation: 8,
								}}
								source={require("../assets/images/what_to_use.png")}
							/>
						</View>
					</TouchableOpacity>
					<TouchableOpacity onPress={() => {}}>
						<View
							style={{
								flexDirection: "row",
								// width: "100%",
								// marginBottom: 10,
								padding: 10,
							}}>
							<AutoHeightImage
								style={{
									width: screenWidth - 20,
									// height:
									height: 80,
									resizeMode: "contain",

									shadowcolor: APP_COLOURS.BACKGROUND,
									shadowOffset: {
										width: 2,
										height: 5,
									},
									shadowOpacity: 0.07,
									shadowRadius: 4.65,
									elevation: 8,
								}}
								source={require("../assets/images/when_to_use.png")}
							/>
						</View>
					</TouchableOpacity>
					<TouchableOpacity onPress={() => {}}>
						<View
							style={{
								flexDirection: "row",
								// width: "100%",
								// marginBottom: 10,
								padding: 10,
							}}>
							<AutoHeightImage
								style={{
									width: screenWidth - 20,
									// height:
									height: 80,
									resizeMode: "contain",

									shadowcolor: APP_COLOURS.BACKGROUND,
									shadowOffset: {
										width: 2,
										height: 5,
									},
									shadowOpacity: 0.07,
									shadowRadius: 4.65,
									elevation: 8,
								}}
								source={require("../assets/images/how_to_use.png")}
							/>
						</View>
					</TouchableOpacity>

					<View
						style={{
							height: 100,
						}}
					/>
				</ScrollView>
				{this.state.startDay !== "" ? this.startDay() : null}
			</View>
		);
	}

	startDay = () => {
		return <RoutinePage />;
	};
}
