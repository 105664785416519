import { Dimensions, LayoutAnimation, UIManager, Platform } from "react-native";

if (Platform.OS === "android") {
	if (UIManager.setLayoutAnimationEnabledExperimental) {
		UIManager.setLayoutAnimationEnabledExperimental(true);
	}
}

export const APP_COLOURS = {
	MAIN_BG: "#131119",
	MAIN: "#131119",
	BACKGROUND: "#17151D",
	PRIMARY: "#17151D",
	SECONDARY: "#0676ED",
	//   BACKGROUND: '#F8FAFF',
	TEXTWHITE: "#F8FAFF",

	BG: "#2B2C31",
	BG2: "#2B2C31",
	BG3: "#35373C",
	BG4: "#4B4E54",
	BG5: "#686868",
	TEXTCOLOR: "#C7C7D2",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	PURPLE: "#8F6FFB",
	BUTTON2: "#252B35",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#F5F5F5",
	WHITE: "#FFF",
	GREEN: "#07D55D",
	GREENCOMPLETE: "rgba(55, 255, 86, 0.4)",
	ORANGE: "#FF8807",
	YELLOW: "#FFD76E",
	RED: "#E35B5B",
	RED2: "#FF5F5F",
	BLUE: "#0676ED",
	BLUE2: "#3F6EFF",
	WHITE_TRANSPARENT: "rgba(212, 212, 212, 0.2)",
	WHITE_TRANSPARENT1: "rgba(212, 212, 212, 0.1)",
	WHITE_TRANSPARENT2: "rgba(25, 29, 36, 0.4)",
	WHITE_TRANSPARENT3: "#323A47",
	WHITE_TRANSPARENT4: "rgba(212, 212, 212, 0.05)",
	WHITE_TRANSPARENT5: "rgba(0, 0, 0, 0.05)",
	WHITE_TRANSPARENT7: "rgba(212, 212, 212, 0.03)",
	WHITE_TRANSPARENT8: "rgba(212, 212, 212, 0.015)",

	WHITE_TRANSPARENT6: "rgba(0, 0, 0, 0.015)",
	WHITE_TRANSPARENT9: "rgba(255, 255, 255, 0.8)",
	WHITE_TRANSPARENT10: "rgba(255, 255, 255, 0.5)",
	BACK_TRANSPARENT: "rgba(0, 0, 0, 0.7)",
	BACK_TRANSPARENT2: "rgba(0, 0, 0, 0.37)",
	BLACK_TRANS: "rgba(52, 52, 52, 0.2)",
	BLACK: "#252525",

	RED_TRANS: "rgba(255, 95, 95, 0.05)",
};

export var CustomLayoutSpring = {
	duration: 400,
	create: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 1,
	},
	update: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 0.9,
	},
};

export const screenWidth = Math.round(Dimensions.get("window").width);
export const screenHeight = Math.round(Dimensions.get("window").height);
