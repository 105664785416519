import React from "react";
import { View } from "react-native";
import { useParams, useLocation } from "react-router-dom";
import HydraplayQuiz from "./Quiz";
import QuizFrame from "./QuizFrame";

function useQuery() {
	// WORKS FOR QURY URL
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function HydraplayUrl() {
	let { retailer } = useParams();
	// let query = useQuery();
	// let id = query.get("id");
	// console.log(retailer);

	return <QuizFrame width={window.innerWidth} retailer={retailer} />;
	// console.log(retailer);
	// return <View />;
}
