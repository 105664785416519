import React, { Component } from "react";
import { View, Text, ScrollView } from "react-native";

export default class Cookiepolicy2 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<ScrollView>
					<View
						style={{
							maxWidth: 800,
							width: "100%",
							marginTop: 100,
						}}>
						<Text>
							<h2>Elizabeth Arden South Africa Cookie Policy</h2>

							<p>
								Elizabeth Arden South Africa (the “Company”,
								“us” or “we”) operates and maintains this
								website (the “Site”). The Company respects your
								privacy and this policy summarises our use of
								cookies and other tracking technologies,
								including, but not limited to web beacons, pixel
								tags, and clear GIFs (collectively, “Cookies and
								Tracking Technologies”), on this Site. This{" "}
								<a href=''>Cookie Policy</a> should be read
								together with our{" "}
								<a href='/privacy-policy/'>Privacy Policy</a>{" "}
								and <a href=''>Terms of Use</a>.
							</p>

							<p>
								By continuing to browse or use the Site, you
								agree that we can store and access Cookies and
								Tracking Technologies as described in this
								Cookie Policy.
							</p>

							<h3>Use of Cookies and Tracking Technologies</h3>

							<p>
								We use Cookies and Tracking Technologies to
								collect certain information by automated means
								when you visit our Site, such as how many users
								visited the Site and the pages they accessed. By
								collecting this information, we learn how to
								best tailor the Site to our visitors.
							</p>

							<h3>Cookies</h3>

							<p>
								Like many companies’ sites, our Site may use
								cookies. Cookies are bits of text that are
								placed on your computer hard drive when you
								visit certain websites. We may use cookies to
								tell us, for example, whether you have visited
								us before or if you are a new visitor, and to
								help us identify site features in which you may
								have the greatest interest. Cookies may enhance
								your online experience by saving your
								preferences while you are visiting a particular
								site.
							</p>

							<h3>Other Tracking Technologies</h3>

							<p>
								Certain pages on our Site may contain other
								Tracking Technologies such as Internet tags,
								pixel tags, and clear GIFs. These Tracking
								Technologies allow third parties to obtain
								information such as the IP address of the
								computer that downloaded the page on which the
								Tracking Technologies appear, the URL of the
								page on which the Tracking Technologies appear,
								the time the page containing the Tracking
								Technologies was viewed, the type of browser
								used to view the page, and the information
								collected and stored in cookies set by the third
								party.
							</p>

							<p>
								You can find more information about Cookies and
								Tracking Technologies at{" "}
								<a
									href='http://www.allaboutcookies.org'
									target='_blank'>
									www.allaboutcookies.org
								</a>{" "}
								and{" "}
								<a
									href='http://www.youronlinechoices.eu'
									target='_blank'>
									www.youronlinechoices.eu
								</a>
								.
							</p>

							<h3>IP Addresses</h3>

							<p>
								An IP address is a unique identifier that
								certain electronic devices use to identify and
								communicate with each other on the Internet.
								When you visit our Site, we may access and
								collect the IP address of the device you use to
								connect to the Internet. We use this information
								to determine the general physical location of
								the device and understand from what geographic
								regions our website visitors come. We also may
								use this information to enhance our Site.
							</p>

							<h3>First and Third-party Cookies</h3>

							<p>
								To administer our Site and for research
								purposes, the Company also has contracted with
								third-party service providers to track and
								analyse statistical usage and volume information
								from our Site users. These third-party service
								providers use persistent cookies to help us to
								improve the user experience, manage our Site
								content, and analyse how users navigate and
								utilise the Site.
							</p>

							<p>
								“First party cookies” are Cookies and Tracking
								Technologies that belong to the Company and that
								the Company places on your device. “Third-party
								cookies” are Cookies and Tracking Technologies
								that another party places on your device through
								our Site. The Company may contract with
								third-party service providers to send e-mails to
								users who have provided us with their contact
								information. To help measure and improve the
								effectiveness of our e-mail communications,
								and/or to determine whether messages have been
								opened and links clicked on, the third-party
								service providers may place Cookies and Tracking
								Technologies on the devices of these users.
							</p>

							<p>
								For more information on how these companies
								collect and use information on our behalf,
								please refer to their privacy policies as
								indicated below.
							</p>

							<p>We use the following types of cookies:</p>

							<p>
								<strong>Persistent cookies</strong>. We use
								persistent cookies to improve your experience of
								using the Site. This includes recording your
								acceptance of our Cookie Policy to remove the
								cookie message which first appears when you use
								the Site.
							</p>

							<p>
								<strong>Session cookies</strong>. Session
								cookies are temporary and deleted from your
								machine when your web browser closes. We use
								session cookies to help us track internet usage
								as described above.
							</p>

							<p>
								You may refuse to accept browser cookies by
								activating the appropriate setting on your
								browser. However, if you select this setting you
								may be unable to access or take full advantage
								of certain parts of the Site. Unless you have
								adjusted your browser setting so that it will
								refuse cookies, our system will issue cookies
								when you direct your browser to our Site.
							</p>

							<p>
								The data collected by the Site and/or through
								cookies that may be placed on your computer will
								not be kept for longer than is necessary to
								fulfil the purposes mentioned above.
							</p>

							<p>
								Our cookies are used for the following purposes:
							</p>

							<table class='cookie-table'>
								<thead>
									<tr>
										<td>
											<p>
												<strong>Cookie Type</strong>
											</p>
										</td>
										<td>
											<p>
												<strong>Purpose</strong>
											</p>
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<p>Strictly Necessary/ Technical</p>
										</td>
										<td>
											<p>
												These cookies are necessary to
												allow us to operate our Site so
												you may access it as you have
												requested. These cookies, for
												example, let us recognise that
												you have created an account and
												have logged into that account to
												access Site content. They also
												include cookies that enable us
												to remember your previous
												actions within the same browsing
												session and secure our Site.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Analytical/ Performance</p>
										</td>
										<td>
											<p>
												These cookies are used by us or
												third-party service providers to
												analyse how the Site is used and
												how it is performing. For
												example, these cookies track
												what pages are most frequently
												visited, and what locations our
												visitors come from. If you
												subscribe to a newsletter or
												otherwise register with the
												Site, these cookies may be
												correlated to you. These cookies
												include, for example, Google
												Analytics cookies.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Functionality</p>
										</td>
										<td>
											<p>
												These cookies let us operate the
												Site in accordance with the
												choices you make. These cookies
												permit us to "remember" you
												in-between visits. For instance,
												we will recognise your user name
												and remember how you customised
												the Site and services, for
												example by adjusting text size,
												fonts, languages and other parts
												of web pages that are alterable,
												and provide you with the same
												customisations during future
												visits.
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Targeting</p>
										</td>
										<td>
											<p>
												These cookies collect
												information about your
												activities on these and other
												sites to provide you targeted
												advertising. We may also allow
												our third-party service
												providers to use cookies on the
												Site for the same purposes
												identified above, including
												collecting information about
												your online activities over time
												and across different websites.
												The third-party service
												providers that generate these
												cookies, such as Adobe, Google,
												LinkedIn, and Facebook, have
												their own privacy policies, and
												may use their cookies to target
												advertising to you on other
												websites, based on your visit to
												our Site.
											</p>
										</td>
									</tr>
								</tbody>
							</table>

							<h3>
								Your choices. Allow, block or remove cookies.
							</h3>

							<p>
								You can stop cookies from being downloaded on
								your computer by selecting the appropriate
								settings on your browser. Most browsers will
								tell you how to stop accepting new cookies, how
								to be notified when you receive a new cookie,
								and how to disable existing cookies.
							</p>

							<h3>Examples:</h3>

							<p>
								On Microsoft Internet Explorer this can be done
								by selecting Tools menu&gt; Internet Options&gt;
								Privacy&gt; Settings;
							</p>
							<p>
								On Firefox, this can be done by selecting
								Tools&gt; Options&gt; Privacy&gt; Cookies;
							</p>
							<p>
								On Google Chrome, this can be done by selecting
								Settings&gt; Privacy;
							</p>
							<p>
								On Safari, this can be done by selecting
								Preferences&gt; Security menu.
							</p>

							<p>
								You can find out how to do this for your
								particular browser by clicking “help” on your
								browser's menu or by visiting{" "}
								<a
									href='http://www.allaboutcookies.org'
									target='_blank'>
									www.allaboutcookies.org
								</a>
								. Please note, however, that without cookies you
								may not be able to access or take full advantage
								of all our Site features.
							</p>

							<p>
								Most browsers allow users to use a “private
								mode” by which cookies are deleted after your
								website visit. Please read the help section of
								your browser to learn more about how to activate
								the “private mode.” You could still visit our
								website if your browser is in “private mode”;
								however, the user experience may not be optimal
								and some utilities may not work.
							</p>

							<p>
								If you want to remove previously-stored cookies,
								you can manually delete the cookies at any time.
								However, this will not prevent the Site from
								placing further cookies on your device unless
								and until you adjust your Internet browser
								setting as described above.
							</p>

							<p>
								For more information on the development of
								user-profiles and the use of
								targeting/advertising cookies, please see{" "}
								<a
									href='http://www.youronlinechoices.eu'
									target='_blank'>
									www.youronlinechoices.eu
								</a>
								.
							</p>

							<h3>Contact Us</h3>

							<p>
								If you have any questions about our information
								practices, please contact us using one of the
								methods described on our Contact Us page, or
								send a letter to the following address:
							</p>

							<Text>
								{`Elizabeth Arden (South Africa) (Pty) Ltd 
Plattekloof House
Tygerberg Park 163 Uys 
Krige Drive Plattekloof 
Cape Town
7500`}
							</Text>
						</Text>
					</View>
				</ScrollView>
			</View>
		);
	}
}
