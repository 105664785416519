const api = "https://us-central1-traq-e3acf.cloudfunctions.net/app/rsvp";

// export const api_qrscanned = api + "/qrCodeScanned";
export const api_formsubmit = api + "/submit";

//
export const api_signup = api + "/app/signup";
export const api_verifyotp = api + "/app/verifyotp";
export const api_login = api + "/app/login";
//
export const api_passwordresetverify = api + "/passwordreset/auth/verify";
export const api_passwordresetreset = api + "/passwordreset/auth/reset";
//

export const api_getUsers = api + "/app/users";
export const api_getChats = api + "/app/chats";
export const api_getChatMessages = api + "/app/getChatMessages";
export const api_chatmessages = api + "/app/chatmessages";
export const api_createchat = api + "/app/createchat";
//
//
//
export const api_addDevice = api + "/axys/dashboard/newDevice";
export const api_getDocuments = api + "/axys/dashboard/getDocuments";
export const api_downloadLog = api + "/axys/dashboard/downloadLog";
export const api_emailFile = api + "/axys/emailFile";
//
//
//
export const api_crudCreate = api + "/app/c";
export const api_crudRead = api + "/app/r";
export const api_crudUpdate = api + "/app/u";
export const api_crudDelete = api + "/app/d";
export const api_crudWithImage = api + "/app/cwimg";
export const api_crudDeleteDocument = api + "/app/cdelimg";
export const api_sendBasicEmail = api + "/app/sendBasicEmail";

//
//
//

export const api_auth_signup = api + "/auth/signup";

//
//
////
//
// TECH SUPPORT
let e = "";

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
// 	e = `http://localhost:5001/cris-c9d88/us-central1/app`;
// } else {
e = `https://us-central1-cris-c9d88.cloudfunctions.net/app`;
// }

export const api_admin_getallmanagertickets = e + "/api/admin/allqueries";
export const api_admin_getpendingmanagertickets =
	e + "/api/admin/pendingqueries";
export const api_admin_managerquerystatus = e + "/api/admin/updatestatus";
export const api_admin_managerqueryaddchat = e + "/api/admin/addChatItem";
