import React, { useState } from "react";
import "./quiz.css";

function HydraplayQuiz() {
	const questions = [
		{
			question:
				"How many great benefits does the NEW Eight Hour® HydraPlay™ Skin Perfecting Daily Moisturizer offer?",
			options: ["3", "5", "8"],
			correctAnswer: "5",
		},
		{
			question:
				"Which great benefit of the NEW Eight Hour® HydraPlay™ Skin Perfecting Daily Moisturizer are you most excited about?",
			options: [
				"💧 Hydrate",
				"💡 Brighten",
				"💎 Perfect & Refine Pores",
				"👑 Prime",
				"〰️ Smooth",
				"🍃 Soothe",
				"🏋️‍♀️ Strengthen Barrier",
				"🛡️ Oil Control",
			],
			correctAnswer: null, // Open-ended, no score calculation
		},
		{
			question:
				"What % of active ingredients of the NEW HydraPlay™ Skin Perfecting Daily Moisturizer are naturally-derived?",
			options: ["85%", "87%", "97%"],
			correctAnswer: "97%",
		},
		{
			question:
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer is vegan.",
			options: ["True", "False"],
			correctAnswer: "True",
		},
		{
			question:
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer can be used...",
			options: ["In the morning", "At night", "Morning and night"],
			correctAnswer: "Morning and night",
		},
		{
			question:
				"Which of these Eight Hour® products is most iconic to you?",
			options: [
				"The Original Eight Hour® Cream Skin Protectant",
				"The NEW HydraPlay™ Skin Perfecting Daily Moisturizer",
				"The Eight Hour® Cream Lip Protectant Stick Sunscreen SPF15",
			],
			correctAnswer: null, // Open-ended, no score calculation
		},
	];

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [score, setScore] = useState(0);
	const [details, setDetails] = useState({ name: "", email: "" });
	const [quizFinished, setQuizFinished] = useState(false);

	const handleAnswerOptionClick = (isCorrect) => {
		if (isCorrect) {
			setScore(score + 1);
		}

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setQuizFinished(true);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setDetails({ ...details, [name]: value });
	};

	return (
		<div className='app'>
			{quizFinished ? (
				<div className='score-section'>
					<h1>Quiz Complete!</h1>
					<p>
						You scored {score} out of {questions.length}
					</p>
					<form>
						<input
							type='text'
							name='name'
							placeholder='Your Name'
							value={details.name}
							onChange={handleInputChange}
						/>
						<input
							type='email'
							name='email'
							placeholder='Your Email'
							value={details.email}
							onChange={handleInputChange}
						/>
						<button type='submit'>Submit</button>
					</form>
				</div>
			) : (
				<>
					<div className='question-section'>
						<h2>Question {currentQuestion + 1}</h2>
						<div className='question-text'>
							{questions[currentQuestion].question}
						</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].options.map(
							(option, index) => (
								<button
									onClick={() =>
										handleAnswerOptionClick(
											option ===
												questions[currentQuestion]
													.correctAnswer,
										)
									}>
									{option}
								</button>
							),
						)}
					</div>
				</>
			)}
		</div>
	);
}

export default HydraplayQuiz;
