import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Image,
} from "react-native";

class RoutinePage extends React.Component {
	state = {
		currentPage: "Title1", // Default page
	};

	handleItemPress = (title) => {
		this.setState({ currentPage: title });
	};

	render() {
		const items = [
			{ title: "Title1", image: require("./ea_logo1.png") },
			{ title: "Title2", image: require("./ea_logo1.png") },
			// ... Add more items here
		];

		return (
			<View style={styles.container}>
				<Page id={this.state.currentPage} />
				<BottomScrollView
					items={items}
					onItemPress={this.handleItemPress}
				/>
			</View>
		);
	}
}

class Page extends React.Component {
	render() {
		// Use the appropriate component for each page
		// let Component;
		// switch (this.props.id) {
		// 	case 1:
		// 		Component = Component1;
		// 		break;
		// 	case 2:
		// 		Component = Component2;
		// 		break;
		// 	// ... Add more cases here for each title
		// 	default:
		// 		Component = () => null; // Empty component
		// }

		return (
			<View style={{ flex: 1 }}>
				<Text>{this.props.title}</Text>
				{/* <Component /> */}
			</View>
		);
	}
}

class BottomScrollView extends React.Component {
	render() {
		return (
			<ScrollView horizontal={true} style={{ flexDirection: "row" }}>
				{this.props.items.map((item) => (
					<ScrollItem
						key={item.title}
						title={item.title}
						image={item.image}
						onPress={this.props.onItemPress}
					/>
				))}
			</ScrollView>
		);
	}
}

class ScrollItem extends React.Component {
	handlePress = () => {
		this.props.onPress(this.props.title);
	};

	render() {
		return (
			<TouchableOpacity
				style={{ marginHorizontal: 10 }}
				onPress={this.handlePress}>
				<Image source={this.props.image} />
			</TouchableOpacity>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "space-between",
	},
});

export default RoutinePage;
